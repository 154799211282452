import * as React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

const PrivacyPolicy = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title

  return (
    <Layout location={location} title={siteTitle}>
      <Seo title="Privacy Policy" />

      <div className="container md:mx-auto mt-6">
        <h1 className="font-bold text-5xl text-center py-10">Privacy Policy</h1>
        <div class="static-content">
          <p>
            Snorkout (“company”, “I”, “we” or “us”) is dedicated to respecting
            the privacy of your personal information, and this privacy policy
            describes what information is collected from you on&nbsp;
            <a href="https://snorkout.com">snorkout.com</a>&nbsp;(hereinafter
            the “website”) and how it is used. The term “you” refers to anyone
            who uses, visits and/or views the website.
          </p>
          <p>
            <strong>
              By visiting and using the website, you accept and agree to be
              bound by this privacy policy. Your continued use of the website
              after posting of any changes to our Privacy Policy constitutes
              your acceptance of those changes and updates. You must not access
              or use the website if you do not wish to be bound by this Privacy
              Policy.
            </strong>
          </p>
          <h2 id="h-children-s-privacy">
            <strong>CHILDREN’S PRIVACY</strong>
          </h2>
          <p>
            We respect the privacy of children and “child” means an individual
            under the age of 13. All information and content on this website is
            intended for individuals over the age of 18. Children under the age
            of 13 are prohibited from using this website. We do not knowingly
            collect, use or disclose personal information from children under
            the age of 13 without prior parental or guardian consent. If you
            believe any personal information is collected from someone under the
            age of 13 without parental or guardian consent, then please contact
            us to have that information deleted.&nbsp;
          </p>
          <h2 id="h-what-information-we-collect-and-how-it-is-used">
            <strong>WHAT INFORMATION WE COLLECT AND HOW IT IS USED</strong>
          </h2>
          <p>
            When you access the website, you may provide certain personally
            identifiable information including but not limited to your name,
            email address, phone number, address, avatar image, credit card
            information when you make a purchase on the website.
          </p>
          <p>
            This information is collected when you register on the site, place
            an order, subscribe to a newsletter, contact us, use the search
            feature on the website, provide comments or any other feedback, fill
            out a form or use the live chat or enter any other information on
            the website to communicate with us. From time to time, we may also
            collect information that you submit when you participate in any
            online surveys that we may post on our website.
          </p>
          <p>
            Your personal information is used to personalize your experience,
            improve the website to better serve you, provide customer service
            support, efficiently process your requests or transactions, tailor
            advertisements to you, elicit reviews of services or products,
            provide you offers, promotions and to follow up with you through
            correspondence (email, live chat, or phone). We may also use this
            information to provide you offers and promotions from our partners
            and/or our affiliates in exchange for a commission without
            additional cost to you.
          </p>
          <p>
            Additionally, like other websites, this website automatically
            collects certain information about you through Log Data and Google
            Analytics. Log Data is information about your computer’s Internet
            Protocol Address, which is your “IP” address, browser information,
            Internet Service Provider’s information, your operating system, and
            your browser type. Similarly, Google Analytics collects certain
            information about your location, browsing history, the pages you
            visit, the equipment you used to access the website, traffic
            patterns, and other general patterns related to your use of the
            website.
          </p>
          <p>
            This information is used to analyze website statistics related to
            user behavior and interests, improve our performance and your use of
            the website and to further enhance our products and services offered
            to you.
          </p>
          <h2 id="h-comments-and-social-media">
            <strong>COMMENTS AND SOCIAL MEDIA&nbsp;</strong>
          </h2>
          <p>
            If you leave a comment, the comment and its metadata are retained
            indefinitely. This is so we can recognize and approve any follow-up
            comments automatically instead of holding them in a moderation
            queue. When you leave a comment, your name, email address and
            website/organization name is not shared with a third party and may
            be used to communicate with you.
          </p>
          <p>
            Social media accounts and sharing options are available on this
            website. Social media sites (Facebook, Twitter, YouTube, and so
            forth) can track your personal information. Should you choose to
            interact with us on social media, please note that you will be
            voluntarily disclosing that personal information. This information
            is no longer private. It becomes public information and can be
            collected and used by others. We have no control over and take no
            responsibility for the use, storage or dissemination of such
            publicly-disclosed personal information by you.
          </p>
          <p>
            Any such interactions via comments and social media with us do not
            subject us to any kind of liability related to misuse of your
            information by others.
          </p>
          <h2 id="h-use-of-cookies">
            <strong>USE OF COOKIES&nbsp;</strong>
          </h2>
          <p>
            The website may use cookies to facilitate your use of the website.
            Cookies are files with small amounts of data including an anonymous
            unique identifier that a website sends to your computer’s hard drive
            when you are viewing the website. Just like other websites, we
            automatically collect some non-personally identifiable information
            including but not limited to your IP address, geographic location,
            language preference, date and time of visitors.
          </p>
          <p>
            When you leave a comment on our website, you may opt-in to saving
            your name, email address and website in cookies. These are for your
            convenience so that you do not have to fill in your details again
            when you leave another comment.
          </p>
          <p>
            If you have an account and you log in to this website, we will set a
            temporary cookie to determine if your browser accepts cookies. This
            cookie contains no personal data and is discarded when you close
            your browser.
          </p>
          <p>
            We may use cookies for various reasons such as optimizing and
            personalizing your browsing experience, checking our website
            analytics, saving your preferences and settings for future use,
            serving ads based on your liking and interests, affiliate marketing
            and posting comments on our website.
          </p>
          <p>
            This information is only collected to better serve and understand
            your user experience on the website.&nbsp; You have the option of
            turning off cookies on your computer should you wish to do so. If
            you choose to do that, you may not be able to view all the features
            and content of this website.
          </p>
          <h2 id="h-use-of-web-beacons-and-pixels">
            <strong>&nbsp;USE OF WEB BEACONS AND PIXELS</strong>
          </h2>
          <p>
            In conjunction with the use of cookies, third parties may also use
            web beacons, which are also known as clear GIFs, web bugs or pixel
            tags to collect general information about your use of our website.
            They monitor user activity and are used to track customer behavior
            data. This information may be relevant to third parties such as the
            ad networks used on our website to tailor the advertising based on
            your behavior and interests.
          </p>
          <p>
            We may use social media pixels to track and collect general
            information about your use in compliance with different social media
            sites (Facebook, Twitter, etc) for the purpose of promoting
            products, tracking conversions, remarketing, running target
            advertisements and so forth.
          </p>
          <p>
            Third parties like Facebook may use their own cookies, web beacons
            and other technologies to collect and receive information from our
            website for the purpose of providing target advertisements. You may
            see our ads on Facebook after you have visited our website. &nbsp;
          </p>
          <h2 id="h-third-party-links-and-use">
            <strong>THIRD-PARTY LINKS AND USE&nbsp;</strong>
          </h2>
          <p>
            We may include, offer or advertise third party links, products or
            services on the website. Once you click on a third-party link and
            leave this website, you are no longer bound by our Privacy Policy
            and&nbsp;Terms and Conditions.
          </p>
          <p>
            Articles on this website may include embedded content (e.g. videos,
            images, advertisements, etc.). Embedded content from other websites
            behaves in the exact same way as if the visitor has visited the
            other website. These websites may collect data about you, use
            cookies, embed additional third-party tracking, and monitor your
            interaction with that embedded content, including tracing your
            interaction with the embedded content if you have an account and are
            logged in to that website.
          </p>
          <p>
            We may use Google AdSense advertising along with any other
            third-party advertising on the website. Google is a third party that
            also uses cookies to serve ads on the website for the purpose of
            providing a positive user experience. Third-party vendors such as
            Google use cookies to serve ads based on a user’s prior visits to
            the website. You can opt-out of Google by visiting their privacy
            policy and ad settings.
          </p>
          <p>
            We have no control over these third parties and they have their own
            privacy policies. Neither are we responsible for the activities and
            practices of these third parties. You should contact them directly
            and read their privacy policies for any questions. You also agree
            that your use of these third-party websites is solely at your risk.
          </p>
          <h2 id="h-disclosure-of-your-information">
            <strong>DISCLOSURE OF YOUR INFORMATION</strong>
          </h2>
          <p>
            As a general rule, we do not disclose your personal information to
            third parties without your consent with the exception of the
            following circumstances:
          </p>
          <ol type="1">
            <li>
              We may disclose your information to our trusted third parties that
              work with us such as our website hosting partners, email marketing
              service provider, other service providers that assist in the
              operation of the website, and any other affiliates and
              subsidiaries we rely upon to provide you products and services
              offered here.
            </li>
            <li>
              We may disclose your information in order to comply with state or
              federal regulations related to copyright infringement lawsuits or
              any other legal claims related to the website.
            </li>
            <li>
              We may disclose your information to our successor and/or acquiring
              party in the event of a merger, acquisition, restructuring,
              dissolution or partial sale in the future. However, your personal
              information will be transferred to the acquiring party in
              accordance with this privacy policy.
            </li>
          </ol>
          <h2 id="h-email-marketing">
            <strong>EMAIL MARKETING&nbsp;</strong>
          </h2>
          <p>
            You have the option of opting in or unsubscribing from our email
            list. By subscribing and opting in, you agree to receive
            newsletters, updates, messages, promotional materials and any other
            content related to this website. When you send an email, your email
            message along with email address and responses are saved for
            communication purposes with you. This information is kept
            confidential and we do not share, sell or trade your email
            information with third parties except as otherwise stated in this
            privacy policy.
          </p>
          <p>
            We do not envision offering goods or services to individuals living
            in the European Union as outlined in the General Data Protection
            Regulation (“GDPR”).
          </p>
          <p>
            If you are in the European Union and opt-in to receive any of our
            free products or services and/or purchase any products or services
            through our website then you will be subscribed to receive our free
            email newsletter once you affirmatively consent to it. Please see
            the&nbsp;<strong>Opt-Out&nbsp;</strong>section below should you wish
            to “unsubscribe” and not receive any emails from us.
          </p>
          <p>
            But if you are NOT in the European Union then you will be
            automatically subscribed to receive our free email newsletter once
            you opt-in to receive any of our free products or services and/or
            purchase any products or services through our website. Please see
            the&nbsp;<strong>Opt-Out&nbsp;</strong>section below should you wish
            to “unsubscribe” and not receive any emails from us.
          </p>
          <h2 id="h-opt-out">
            <strong>OPT-OUT</strong>
          </h2>
          <p>
            We comply with the CAN-SPAM Act of 2003 and do not spam or send
            misleading information. Should you wish to no longer receive
            communication from us, you have the option of unsubscribing by
            clicking “unsubscribe” at the bottom of the email we send to you or
            by contacting us.
          </p>
          <p>
            As for third party websites, please contact them directly to
            unsubscribe and/or opt-out from their communications.
          </p>
          <p>
            We are in compliance with the GDPR along with the email marketing
            service we use to collect your data.&nbsp;&nbsp;
          </p>
          <h2 id="h-gdpr-visitor-rights">
            <strong>GDPR VISITOR RIGHTS</strong>
          </h2>
          <p>
            Under the GDPR, if you are within the European Union, you are
            entitled to certain rights and information listed below.
          </p>
          <p>
            We will retain any information you choose to provide to us until the
            earlier of:
          </p>
          <ol type="1">
            <li>
              You ask us to delete the information by sending a request
              to&nbsp;contact@snorkout.com. Please note that such requests may
              result in you no longer being able to access paid or free content
              previously provided to you.
            </li>
            <li>Our decision to cease using our existing data providers.</li>
            <li>
              The Company decides to no longer be in business or continue to
              offer the services.
            </li>
            <li>
              The data is no longer needed to provide you service, is too costly
              to maintain further retention, or the Company finds it outdated.
            </li>
          </ol>
          <p>
            You have the right to request access to your data that we store and
            have the ability to access your personal data.
          </p>
          <p>
            You have the right to either rectify or erase your personal data.
            You have the right to verify the accuracy of your personal data and
            have it corrected or removed completely
          </p>
          <p>
            You have the right to seek restrictions on the processing of your
            data. When you restrict the processing of your data, we can store
            your data but cannot process it further.
          </p>
          <p>
            You have the right to object to the processing of your data in
            certain circumstances including but not limited to direct marketing,
            profiling, scientific or historical research purposes, statistical
            purposes, automated decision making and profiling and tasks based on
            legitimate interests or in the public interest/exercise of official
            authority.
          </p>
          <p>
            You have the right to the portability of your data. You have the
            right to request your personal data from us, receive it and transfer
            it to another controller.
          </p>
          <p>
            You have the right to withdraw consent at any time. If you have
            provided consent to the Company’s processing of your personal data,
            you have the right to withdraw that consent any time without
            affecting the lawfulness of processing based upon consent that
            occurred prior to your withdrawal of consent.
          </p>
          <p>
            You have the right to lodge a complaint with a supervisory authority
            that has jurisdiction over issues related to the General Data
            Protection Regulation.
          </p>
          <p>
            We require only the information that is reasonably necessary to
            enter into a contract with you. We will not require you to provide
            consent for any unnecessary processing as a condition of entering
            into a contract with us.
          </p>
          <h2 id="h-your-rights-under-the-california-consumer-privacy-act-ccpa-compliance">
            <strong>
              YOUR RIGHTS UNDER THE CALIFORNIA CONSUMER PRIVACY ACT (CCPA
              COMPLIANCE)
            </strong>
          </h2>
          <p>
            If you reside in California, you are entitled to additional rights
            under California law and we ensure compliance with the CCPA. Here
            are your rights:
          </p>
          <ol type="1">
            <li>
              You have the right to know whether your personal information is
              sold or disclosed to third parties.
            </li>
            <li>
              You have the right to say no to the sale of your personal
              information.
            </li>
            <li>
              You also have the right to access your personal information, which
              we will provide within 30 days of such request.
            </li>
            <li>
              You have the right to know what personal information is collected
              from you and how it is used, which we explained in this Privacy
              Policy
            </li>
            <li>
              You have the right to have your personal information deleted
            </li>
            <li>
              You have the right to equal service, price and no discrimination
            </li>
            <li>
              You have the right to data portability and right to request your
              personal information and use it for your own purposes
            </li>
          </ol>
          <p>
            Pursuant to California’s “Shine the Light Act,” you are permitted to
            request information about the manner in which we share certain
            categories of information with third parties for their marketing
            use. We may disclose your personal information to our affiliates or
            other related third parties such as service providers, vendors for
            their use in marketing to you, so we can provide the products and/or
            services offered on this website to you. When we disclose such
            information, your personal information is still kept confidential
            and between us and that third party. It is not used for any other
            purpose that’s not permitted under the laws.
          </p>
          <p>
            Please be advised we DO NOT SELL YOUR PERSONAL INFORMATION to third
            parties and have never sold your personal information. We do not
            intend to sell your personal information in the future either.
          </p>
          <p>
            Under the CCPA, you still have the right to opt-out of such sales
            and send us a “do not sell my information” request. If you would
            like to exercise any of your rights under California law, please
            submit a verifiable consumer request to us by sending us an email at
            contact@snorkout.com. Only you as the person registered with the
            California Secretary of State can make such verifiable consumer
            requested related to your personal information or someone you
            authorize to act on your behalf.
          </p>
          <p>
            Your verifiable consumer request must provide sufficient information
            that allows us to verify that you are the person you are claiming to
            be or that you are the authorized representative of such person
            about whom we had collected personal information. You must describe
            your request with enough details such as your first and last name,
            address and your country that allow us to properly understand the
            request and respond to it. Please note we cannot respond to your
            request or provide you with personal information unless we first
            verify your identity or authority to make such a request and confirm
            that the personal information relates to you. We will make all
            attempts to respond to your request within 30 days of receipt.
          </p>
          <p>
            This privacy notice for California residents supplements the
            information included in the previous sections of this privacy
            policy. California and Delaware law also requires us to state
            whether we honor “Do Not Track” settings in your browser regarding
            targeted advertising and we do not monitor or respond to Do Not
            Track browser requests.
          </p>
          <h2 id="h-security">
            <strong>SECURITY</strong>
          </h2>
          <p>
            The security of your personal information is important to us, and we
            strive to follow generally commercial industry standards to protect
            your personal information submitted to us voluntarily and
            automatically. However, no method of transmission over the Internet
            is 100% secure and we cannot guarantee the absolute security of your
            information. When you make a credit card purchase or purchase
            through any means on the website, you will be directed to a
            third-party vendor to complete the transaction. Any information you
            provide during the checkout process is not stored on our website but
            instead provided to the third-party vendor that completes the
            purchase transaction.
          </p>
          <p>
            By using this website, you agree to hold us harmless for any
            security breach and for any unauthorized use of your personal
            information by third parties. You also agree that we cannot be held
            responsible for any disclosure of your information through our
            website without our knowledge and consent.
          </p>
          <h2 id="h-privacy-policy-updates">
            <strong>PRIVACY POLICY UPDATES&nbsp;</strong>
          </h2>
          <p>
            This privacy policy is effective as of May 1, 2020 and will be
            updated and modified as needed. You are responsible for visiting
            this page periodically to check for future updates to this policy.
            Any modifications to this privacy policy will be effective upon our
            publishing of the new terms, and your continued use of our website
            after the posting of any updates constitutes your acceptance of our
            modified privacy policy.
          </p>
        </div>
      </div>
    </Layout>
  )
}

export default PrivacyPolicy

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
